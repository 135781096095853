
function makeTitle(word) {
    if (word)
        return word.split(" ").map(word => word[0].toUpperCase() + word.substring(1)).join(" ")
}

function updateDisplayPath(pathname)
{
    let path = pathname.split("/");
    for (let i = 0; i < path.length; i++)
    {
        if (path[i] === "courses" && i + 2 < path.length)
        {
            path[i + 2] = "Course Details"; 
        }
        if (path[i] === "courses" && i + 3 < path.length) {
            path[i + 3] = "Unit";
        }
        if (path[i] === "courses" && i + 4 < path.length) {
            path[i + 4] = "Lesson";
        }
        if (path[i] === "courses" && i + 5 < path.length) {
            if (path[i + 5].includes("activity"))
            {
                path[i + 5] = "Activity";
            }
            else {
                path[i + 5] = "Quiz";
            }

        }
        path[i] = makeTitle(path[i]);
    }

    return path.join("/");
}

function generateCode(length) {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let randStr = "";
    for (let i = 0; i < length; ++i)
    {
        randStr += chars[Math.round(Math.random() * (chars.length - 1))];
    }
    return randStr;
}

export {makeTitle, updateDisplayPath, generateCode};