import { useEffect, useState, useContext, useMemo } from "react";
import { firestoreDb } from '../../assets/js/firebase-config';
import { doc, getDoc} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeaderContext } from "../../components/PageHeaderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePen, faBookOpen, faCode } from '@fortawesome/free-solid-svg-icons'
import CodeEditor from "../../components/code/CodeEditor";
import ProblemDescription from "../../components/code/ProblemDescription"; 
import ResizableTwoPanel from "../../components/general/ResizableTwoPanel";

export default function PythonActivity()
{
    const { classID, courseID, unitID, lessonID } = useParams();
    const { setPageHeader } = useContext(PageHeaderContext);
    const [ lessonDetails, setLessonDetails ] = useState(null);
    const [ activityIdx, setActivityIdx ] = useState(0);
    const [ editorValues, setEditorValues ] = useState([]);
    const [ instructionsCompleted, setInstructionsCompleted ] = useState(null);

    const instructIdx = useMemo(() => {
        // Figure out the current instruction index based on the first false 
        if (instructionsCompleted)
        {
            const updatedInstructIdx = instructionsCompleted[activityIdx].findIndex(item => !item);
            return updatedInstructIdx;
        } else {
            return -1;
        }
         
    }, [instructionsCompleted, activityIdx]);
    
    let navigate = useNavigate();

    useEffect( () => {
        const fetchData = async() => { 
            try {
                const lessonRef = doc(firestoreDb, `Courses/${courseID}/Units/${unitID}/Lessons/${lessonID}`);
                const docSnap = await getDoc(lessonRef);
                const lessonData = docSnap.data();
                
                setLessonDetails(lessonData);
                setPageHeader(`${lessonData.name} Python Activity`);

                // Set the initial editor values
                const initEditorValues = lessonData.python_activity.map((activity) => {
                    if (activity.editor) {
                        return activity.editor;
                    } else {
                        return "";
                    }
                });

                setEditorValues(initEditorValues);
                
                // Create the initial completed by setting them all to false
                setInstructionsCompleted(lessonData.python_activity.map(
                    (item) => item.answers.map(item => false)));

            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);

    // TODO: THIS NEEDS TO BE MOVED TO A CLOUD FUNCTION
    /* Validate the output of the Python code */
    function validateOutput(terminalOutput) {
        // Split the terminal output into lines
        const outputLines = terminalOutput.split(/\r?\n|\r|\n/g);
        if (outputLines.length === 0) {
            return;
        }
    
        // Remove the last line that contains the metadata about the program
        const localsLine = outputLines.pop().split("<ENDOFCODE/> ").pop();
        if (!localsLine) {
            return;
        }
    
        const localsPart = localsLine.replaceAll("}", "").split("_pyodide_core': <module '_pyodide_core' (built-in)>")[1];
        // if (!localsPart) {
        //     return;
        // }
    
        const locals = localsPart.split(", ");
        const localVars = {};
    
        // Iterate over the variables from locals (that were created by the user)
        for (let i = 1; i < locals.length; i++) {
            // Add the variable, function, class, etc.
            const varDictSplit = locals[i].replaceAll("'", "").split(":");
            if (varDictSplit.length === 2) {
                localVars[varDictSplit[0].trim()] = varDictSplit[1].trim();
            }
        }
    
        terminalOutput = outputLines.join("\r\n");
        let curIntructIdx = instructIdx;
        let prevIntructIdx = -1;
    
        while (curIntructIdx !== -1 && curIntructIdx < lessonDetails.python_activity[activityIdx].answers.length && prevIntructIdx !== curIntructIdx) {
            prevIntructIdx = curIntructIdx;   
            let correctAnswers = lessonDetails.python_activity[activityIdx].answers[curIntructIdx];
    
            if (correctAnswers.hasOwnProperty("alternate")) {
                correctAnswers = correctAnswers.alternate;
                if (typeof correctAnswers === "string") {
                    correctAnswers = [correctAnswers];
                }
                let shouldBreakOuter = false;
                for (let i = 0; i < correctAnswers.length; i++) {
                    const correctAnswer = formatCorrectAnswer(correctAnswers[i]);
                    if (terminalOutput.trim() === correctAnswer) {
                        const curInstructionsCompleted = [...instructionsCompleted];
                        curInstructionsCompleted[activityIdx][curIntructIdx] = true;
                        setInstructionsCompleted(curInstructionsCompleted);
                        curIntructIdx++;
                        break;
                    }
                    for (let j = 0; j < outputLines.length; j++) {
                        if (outputLines[j].trim() === correctAnswer) {
                            const curInstructionsCompleted = [...instructionsCompleted];
                            curInstructionsCompleted[activityIdx][curIntructIdx] = true;
                            setInstructionsCompleted(curInstructionsCompleted);
                            curIntructIdx += 1;
                            shouldBreakOuter = true;
                            break;    
                        }
                    }
                    if (shouldBreakOuter) {
                        break;
                    }
                }
            } else {
                let correctVariables = correctAnswers.variable;
                let hasAllVars = true;
                for (const propertyVar in correctVariables) {
                    if (!(localVars.hasOwnProperty(propertyVar) && localVars[propertyVar] === correctVariables[propertyVar])) {
                        hasAllVars = false;
                        break;
                    }
                }
                if (hasAllVars) {
                    const curInstructionsCompleted = [...instructionsCompleted];
                    curInstructionsCompleted[activityIdx][curIntructIdx] = true;
                    curIntructIdx += 1;
                    setInstructionsCompleted(curInstructionsCompleted);
                }
            }
        }
    }
    

    function formatCorrectAnswer(correctAnswer)
    {
        const userData = JSON.parse(localStorage.getItem("user"));
        
        // Replace with first name
        let formattedAnswer = correctAnswer.replace(
            "<FIRST_NAME>",
            userData.userData.first_name
        );

        return formattedAnswer;
    }

    return (
        <div className="python-activity-page">
            { lessonDetails &&
                <>
                <div className="python-activity-panels">
                <ResizableTwoPanel 
                    PanelOne={<ProblemDescription
                        title={lessonDetails.python_activity[activityIdx].title}
                        description={lessonDetails.python_activity[activityIdx].description}
                        instructions={lessonDetails.python_activity[activityIdx].instructions}
                        codeSnippets={lessonDetails.python_activity[activityIdx].code_snippet}
                        instructionsCompleted={instructionsCompleted[activityIdx]}
                        />}
                    PanelTwo={<CodeEditor
                        editorContent={editorValues[activityIdx]}
                        setEditorContent={(editorValue) => {
                            const copyEditor = [...editorValues];
                            copyEditor[activityIdx] = editorValue;
                            setEditorValues(copyEditor);                            
                        }}
                        setCodeOutput={(output) => {
                            validateOutput(output);
                            // NEED TO PERFORM A FIREBASE FUNCTION HERE TO VALIDATE OUTPUT
                        }}
                    />}/>
                </div>

            <div className="lesson-previous-button-container">
                { activityIdx === 0 &&
                <button 
                    onClick={() => navigate(`/courses/${classID}/${courseID}/${unitID}/${lessonID}`)}
                    className="c-button lesson-quiz-button">
                        <div className="lesson-quiz-button-title">
                            Lesson
                        </div>
                        <FontAwesomeIcon 
                            className="lesson-quiz-button-icon"
                            icon={faBookOpen}
                        />
                </button>
                }
                { activityIdx !== 0 &&
                 <button 
                 onClick={ () => setActivityIdx(activityIdx - 1)}
                 className="c-button lesson-quiz-button">
                     <div className="lesson-quiz-button-title">
                         Previous Problem
                     </div>
                     <FontAwesomeIcon 
                         className="lesson-quiz-button-icon"
                         icon={faCode}
                     />
                </button>
                }
                { activityIdx + 1 >= lessonDetails.python_activity.length &&
                <button 
                    disabled={!instructionsCompleted[activityIdx][instructionsCompleted[activityIdx].length - 1]}
                    onClick={() => navigate(`/courses/${classID}/${courseID}/${unitID}/${lessonID}/${lessonDetails.quiz_id}`)}
                    className="c-button lesson-quiz-button">
                        <div className="lesson-quiz-button-title">
                            Up Next: Quiz
                        </div>
                        <FontAwesomeIcon 
                            className="lesson-quiz-button-icon"
                            icon={faFilePen}
                        />
                </button>
                }
                { activityIdx + 1 < lessonDetails.python_activity.length &&
                <button 
                    disabled={!instructionsCompleted[activityIdx][instructionsCompleted[activityIdx].length - 1]}
                    onClick={ () => setActivityIdx(activityIdx + 1)}
                    className="c-button lesson-quiz-button">
                        <div className="lesson-quiz-button-title">
                            Next Problem
                        </div>
                        <FontAwesomeIcon 
                            className="lesson-quiz-button-icon"
                            icon={faCode}
                        />
                </button>
                }
            </div>
            </>   
            }
        </div>
    );
}