import { Link, useNavigate, useParams } from "react-router-dom";
import EmblaCarousel from "../../components/Slideshow";
import { useEffect, useState } from "react";
import { firestoreDb } from '../../assets/js/firebase-config';
import { doc, getDoc} from "firebase/firestore";
import { LearningObjectives, LessonDetails } from "./LessonParts";
import { useContext } from "react";
import { PageHeaderContext } from "../../components/PageHeaderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePen, faGamepad, faCode} from '@fortawesome/free-solid-svg-icons'
import LessonWithSlides from "../../components/class/LessonWithSlides";
import LessonArticle from "../../components/class/LessonArticle";



const lessonDetailsTemp = {
    type: "article",
    name: "Introduction to Computer Science",
    learning_outcomes: [
        "Describe the history and evolution of computer science.",
        "Identify different areas within the field of computer science.",
    ],
    standards: [
        "CS.IC.1a: Discuss the ethical ramifications of hacking and its impact on society.",
        "CS.IC.1b: Explain the privacy concerns related to the collection and generation of data."
    ],
    quiz_id: "",
    content: {
        sections: [
            {
                header: "What is Computer Science",
                paragraphs: [
                    "<b>Computer Science</b> is the study of what computers can do; it combines problem-solving with technology.",
                    "It’s not just about programming but also about understanding how computers work and what they can achieve. This field is behind everything from social media algorithms to the software in self-driving cars.",
                ]
            },
            {
                header: "The History of Computer Science",
                paragraphs: [
                    "The history of computer science is like a journey that shows how humans created and developed the amazing machines we call computers today.",
                    "It all started a long time ago, even before there were actual computers. People used to do calculations using things like the abacus, which is an ancient counting tool. Then, in the 1800s, a mathematician named Charles Babbage had this incredible idea for a machine called the Analytical Engine, which is considered the first design for a general-purpose computer. Even though it was never fully built during his time, his concepts became the foundation for modern computers.",
                    "Fast forward to the mid-20th century, around the 1940s and 1950s, when the first real computers were invented. They were huge machines that took up entire rooms! One of the most famous early computers was called <b>ENIAC</b>, and it was used for calculations during World War II. Early computing pioneers like <b>Alan Turing</b> helped defeat codes in World War II and laid the groundwork for modern computing.",
                    `Apart from his war efforts, Turing was also a key figure in laying the groundwork for computers as we know them today. He developed the concept of a theoretical computing machine that could perform any mathematical computation—a machine that laid the foundation for modern computers. This hypothetical device is now known as the <b>Turing machine</b>.`,
                    `Turing also proposed the idea of artificial intelligence and created what is known as the <b>Turing Test.</b> It's a way to <u>assess a machine's ability to exhibit intelligent behavior indistinguishable from that of a human.</u>`,
                    `As time passed, computers got smaller, faster, and more powerful. This led to the development of programming languages that made it easier for people to communicate with computers. Instead of using complicated binary code, which is a series of 0s and 1s, programmers could write code in languages closer to human language, like <b>Fortran</b> and <b>COBOL</b>.`,
                    `Then came the personal computer revolution in the 1970s and 1980s, when computers started becoming smaller and more affordable. This led to the birth of companies like Apple and Microsoft, which brought computers into homes and schools.`,
                    `Today, computer science has expanded into so many amazing fields, like artificial intelligence, cybersecurity, video game development, and much more! It's a constantly evolving and exciting field that keeps changing the way we live, work, and communicate with each other.`,
                ],
            },
            {
                header: "Careers within Computer Science",
                paragraphs: [
                    "Computer science opens up a world of exciting careers that involve creating, problem-solving, and exploring the potential of technology.",
                    {
                        header: "Here are some cool career paths within computer science:",
                        ul: [
                            "<b>Software Developer/Engineer</b>: These are the folks who write code to create apps, software, and systems that we use every day. They can specialize in areas like web development, mobile apps, or even creating operating systems.",
                            "<b>Data Scientist</b>: Data scientists work with big sets of information, analyzing and interpreting data to discover patterns and trends. They use this information to solve problems or make predictions, and they often work in fields like healthcare, finance, or marketing.",
                            "<b>Cybersecurity Specialist</b>: These are the protectors of the digital world! They focus on keeping computers, networks, and systems safe from hackers and cyber threats. It's like being a digital detective, finding and fixing security weaknesses.",
                            "<b>Game Developer</b>: If you love video games, this could be your dream job! Game developers create the worlds, characters, and gameplay that make up video games. It's a mix of coding, art, and storytelling.",
                            "<b>AI/Machine Learning Engineer</b>: These professionals work on creating artificial intelligence (AI) systems and teaching computers to learn from data. They build things like smart assistants, recommendation systems, and autonomous vehicles.",
                        ]
                    }
                    
                ],
            },
        ],
    }
};

export default function Lesson()
{
    const { classID, courseID, unitID, lessonID } =  useParams();
    const [ lessonDetails, setLessonDetails ] = useState();
    const {curPageHeader, setPageHeader} = useContext(PageHeaderContext);
    const [ slidesSrc, setSlidesSrc ] = useState();
    const [lessonType, setLessonType ] = useState("slides");

    let navigate = useNavigate();

    useEffect(()=> {
        const fetchData = async() => {
            try {
                // Get the lesson data
                const lessonRef = doc(firestoreDb, `Courses/${courseID}/Units/${unitID}/Lessons/${lessonID}`)
                const docSnap = await getDoc(lessonRef);
                const lessonData = docSnap.data();

                // Get the class data for slides if they are available
                if (Object.hasOwn(lessonData, "slides"))
                {
                    if (classID)
                    {
                        const classRef = doc(firestoreDb, `Class/${classID}`)
                        const classDoc = await getDoc(classRef);
                        const classData = classDoc.data();
                        let tempSrc = "";
                        console.log(classData);
                        if (classData) {
                            tempSrc = lessonData.slides.find(e => e.grade_level === classData.grade_level).src;
                            console.log(tempSrc);
                        }
                        
                        if (tempSrc)
                        { 
                            console.log(tempSrc);  
                            setSlidesSrc(tempSrc); 
                        } 
                        else {
                            setSlidesSrc(lessonData.slides.find(e => e.grade_level === "High School").src);
                        }
                    }
                    else {
                        setSlidesSrc(lessonData.slides.find(e => e.grade_level === "High School").src);
                    }
                }
                

                if (Object.hasOwn(lessonData, "type"))
                {
                    setLessonType(lessonData.type);
                }

                setLessonDetails(lessonData);
                setPageHeader(lessonData.name);
            } catch (error) {
                console.error(error);
            }
            
        };
        fetchData();
    }, []);
    return (
        <div className="c-page-container">
        {lessonDetails &&
        <div className="lesson-page">
            { lessonType !== "article" &&
                <LessonWithSlides lessonDetails={lessonDetails} slidesSrc={slidesSrc}/>
            }
            { lessonType === "article" &&
                <LessonArticle lessonDetails={lessonDetails} />
                
            }
            
            {/* {lessonDetails.slides &&
            <div className="slides-container">
                <EmblaCarousel slides={lessonDetails.slides}/>
                
            </div>
            } */}
            
            {/* <Link 
                to={`/courses/${courseID}/${unitID}/${lessonID}/quiz`}
                className="btn btn-primary btn-block lesson-quiz-button">
                Quiz
            </Link> */}

            <div className="lesson-quiz-button-container">
            {lessonDetails.activity &&
                <button 
                onClick={() => navigate(`/courses/${classID}/${courseID}/${unitID}/${lessonID}/activity`)}
                className="c-button lesson-quiz-button">
                    <div className="lesson-quiz-button-title">
                        Up Next: Activity
                    </div>
                    <FontAwesomeIcon 
                        className="lesson-quiz-button-icon"
                        icon={faGamepad}
                    />
                </button>
            }
            {lessonDetails.python_activity &&
                <button 
                onClick={() => navigate(`/courses/${classID}/${courseID}/${unitID}/${lessonID}/python_activity`)}
                className="c-button lesson-quiz-button">
                    <div className="lesson-quiz-button-title">
                        Up Next: Python
                    </div>
                    <FontAwesomeIcon 
                        className="lesson-quiz-button-icon"
                        icon={faCode}
                    />
                </button>
            }
            { !lessonDetails.activity && !lessonDetails.python_activity &&
                <button 
                onClick={() => navigate(`/courses/${classID}/${courseID}/${unitID}/${lessonID}/${lessonDetails.quiz_id}`)}
                className="c-button lesson-quiz-button">
                    <div className="lesson-quiz-button-title">
                        Up Next: Quiz
                    </div>
                    <FontAwesomeIcon 
                        className="lesson-quiz-button-icon"
                        icon={faFilePen}
                    />
                </button>
            }

            </div>

            
        </div>
        }
        </div>
    )
}